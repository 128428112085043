<template>
    <div class="p-3">
        <el-card v-for="treeNode in productTypeTreeNodes" :key="treeNode.value" :header="treeNode.label" shadow="never"
            class="mb-3" body-style="padding: 8px;">
            <div class="row no-gutters">
                <div v-for="treeNode2 in treeNode.children" :key="treeNode2.value" class="col-2">
                    <div class="bg-light m-2 p-3 rounded-lg">
                        {{treeNode2.label}}
                    </div>
                </div>
            </div>
        </el-card>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                productTypeTreeNodes: [],
            };
        },
        methods: {
            async loadProductTypeTreeNodes() {
                let response = await this.$axios.get('/api/ProductType/GetProductTypeTreeNodes');
                this.productTypeTreeNodes = response.data;
            },
        },
        created() {
            this.loadProductTypeTreeNodes();
        },
    };
</script>